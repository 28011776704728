import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subject, takeUntil, take } from 'rxjs';
import { GraphContainer, NavigationType, GraphNavigation, TimePeriods, GraphMenu, GraphType} from 'src/app/model/generic-graph';
import { NavigationItem, RTSparkChart, SubNavigationItem } from 'src/app/model/production-views';
import { NavigationOptions } from 'src/app/model/queryParams';
import { VerticalGraphViewData } from 'src/app/model/vertical-views';
import { DataService } from 'src/app/services/data.service';

/**
 * @description
 * This component acts the container of a division's graph (e.g. 'Sales', 'Net Income').
 * The component displays the graph's data according to the template it recieves.
 * It manages time tabs navigation ( @see timeTabs ) and slider.
 */

//enum for Interscting with view screen
export enum IntersectionState {
  FirstTimeEntering,
  Entering,
  Inside,
  Leaving,
  BeforeEnter,
}

@Component({
  selector: 'app-section-layout',
  templateUrl: './section-layout.component.html',
  styleUrls: ['./section-layout.component.scss'],
})
export class SectionLayoutComponent implements OnInit, OnDestroy {

  private initialRun = true;
  private tempIntersectionState = IntersectionState.BeforeEnter;

  private handleEnterState(isIntersecting: boolean) {
    if (isIntersecting) {
      if(this.initialRun) {
        this.onIntersection.emit(IntersectionState.FirstTimeEntering)
        this.initialRun = false;
      }
      this.onIntersection.emit(IntersectionState.Entering)
      this.tempIntersectionState = IntersectionState.Entering;
      setTimeout(() => {
        if(this.tempIntersectionState === IntersectionState.Entering){
          this.onIntersection.emit(IntersectionState.Inside);
          this.tempIntersectionState = IntersectionState.Inside;
          this.dataService.setGraph(this.sectionTitle as string, false);
        }
      }, 500);
    }
    else {
      this.onIntersection.emit(IntersectionState.Leaving);
      this.tempIntersectionState = IntersectionState.Leaving;
    }
  }

  private intersectionObserver = new IntersectionObserver(
    (entries) => {
      const entry = entries[0];
      this.handleEnterState(entry.isIntersecting);
    },
    { threshold: 0.45 }
  );

  @Input() dataForChart!: GraphContainer;
  @Input() itemTemplate!: TemplateRef<any>;
  @Input() itemTemplateTest!: TemplateRef<any>;

  @Output() onIntersection: EventEmitter<IntersectionState> = new EventEmitter<IntersectionState>()
  @Output() updateNavState: EventEmitter<Object> = new EventEmitter<Object>();

  @ViewChild('header') headerRef!: ElementRef;

  public componentState: any;
  public setComponentState(state: any) {
    this.componentState = state;
    this.cdr.detectChanges();
  }

  private slick: any;
  private _destroyed$ = new Subject();

  /**
   * @property {any} navData contains an array with graphs' data of each time tab
   * @property {NavigationOptions} navInfo contains the information of the current url path (query params)
   * @property {{ key: string, name: string, disabled: boolean }[]} timeTabs array of tabs representing time periods (monthly, quarterly and yearly)
   */
  public navData: any[] = [];
  public navInfo: NavigationOptions= {};
  public sectionTitle: String = '';
  public isGross: boolean = false;
  public graphTypes: any = GraphType;
  public navigationTypes: any = NavigationType;

  public hasDropdown: boolean = false;
  public isDropdownOpen: boolean = false;
  public currentDropdownIndex: number = 0;
  public dropdownOption: String[] = [];

  public currentTab: number = 0;
  public timeTabs: { key: string; name: string; disabled: boolean }[] = [];

  public currentSlide: number | undefined;
  public sliderConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    initialSlide: 0,
  };

  constructor(private  dataService: DataService, private cdr: ChangeDetectorRef, private nativeElementRef: ElementRef) { }

  public currentBUIndex: number = 0;
  public currentSBUIndex: number = 0;

  ngOnInit(): void {
    this.isGross = this.dataForChart.type === "GROSS"
    this.sectionTitle =  this.dataForChart.title;
    this.sliderConfig.customPaging = (slider: any, index: number): string => {
      return this.customNav(slider, index);
    };
    this.initCurrentData();
    this.initNavInfo();
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {this.scrollToGraph();})
    //Observe the native element
    this.intersectionObserver.observe(this.nativeElementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  /**
   * This function sets the default navigation parameters (time tab, slide, dropdown) according to the current url path
   */
  private initNavInfo(): void {
    this.dataService.currentPath.pipe(take(1)).subscribe((params: NavigationOptions) => {
      if (params.graph && params.graph === this.sectionTitle) {
        if(params.dropdown){
          let index = this.dropdownOption.findIndex(option => option === params.dropdown);
          if(index > -1 && index < this.dropdownOption.length) {
            this.currentDropdownIndex = index;
          }
        }
        if (params.nav) {
          this.changeTab(+params.nav);
        }

        if (params.slide !== undefined) {
          const slideIndex = +params.slide;
          if (slideIndex > -1 && slideIndex < this.navData[this.currentTab].length) {
            this.currentSlide = slideIndex;
          }
        } else {
          if(this.navData[this.currentTab].length === 3) {
            this.currentSlide = 1;
          } else {
            this.currentSlide = 0;
          }
        }
        if(this.dataForChart.subMenu){
          let shouldEmit = false;
            const timeTabSubMenu = this.dataForChart.subMenu;
          if (params.bu){
            const index = timeTabSubMenu.findIndex(el => {
                if (params.buSubtitle) {
                  return params.buSubtitle?.toLowerCase() === el.subtitle?.toLowerCase() && params.bu === el.title;
                } else {
                  return params.bu === el.title;
                }
              });
            if(index > -1 ) {
              this.currentBUIndex = index;
              shouldEmit = true;
            }
          }
          if (params.sbu){
            const index = (timeTabSubMenu[this.currentBUIndex].data ?? []).findIndex(el => el.title === params.sbu);
            if (index > -1 ) {
              this.currentSBUIndex = index;
              shouldEmit = true;
            }
          }
        if(shouldEmit) {
          this.emitNavState();
        }
      }

    this.navInfo = {
      ...params,
      nav: this.currentTab,
      slide: this.currentSlide ?? 0,
      dropdown: this.dropdownOption[this.currentDropdownIndex],
      bu: this.currentBUIndex,
      sbu: this.currentSBUIndex
    };
    };
    });
  }

  public onSlickInit(event: any) {
    this.slick = event.slick;
  }

  /**
   * @description scrolls view to the selected graph
   */
  private scrollToGraph(): void {
    this.dataService.currentPath
      .pipe(takeUntil(this._destroyed$))
      .subscribe((params) => {
        if (params.graph && params.graph === this.sectionTitle && this.tempIntersectionState !== IntersectionState.Inside) {
          setTimeout(() => {
            this.headerRef.nativeElement.scrollIntoView({ behavior: 'auto', alignToTop: true });
          });
        }
      });
  }

  /**
   * This function returns the current navigation state of this graph's section
   */
  public getCurrentNavState(): NavigationOptions {
    //TODO: test amount of calls
    return {
      graph: this.sectionTitle,
      nav: this.currentTab,
      slide: this.slick ? this.slick.currentSlide : this.currentSlide,
      dropdown: this.dropdownOption[this.currentDropdownIndex],
      bu: this.currentBUIndex,
      sbu: this.currentSBUIndex,
    };
  }

  /**
   * This function creates a custom dot for slide navigation
   * @param slider instance of slick slider
   * @param index index of current slide
   * @returns html string for slide's navigation dot
   */
  private customNav(slider: any, index: number): string {
    let navName = this.navData[this.currentTab][index].title;
    return `<button class="paginator"></button> <span class="paginator-label">${navName}</span>`;
  }

  /**
   * This function extracts the graph's data according to its type and initializes @see navData
   * with an array of each time tab
   */
  private initCurrentData(): void {
    let nav = this.dataForChart.navigation.nav;
    switch (this.dataForChart.navigation.type) {
      case NavigationType.GraphNavigation:
        nav = nav as GraphNavigation;
        this.createTimeTabs(nav);
        this.setDefaultTimeTabIndex();
        this.navData = [nav.month, nav.qtd, nav.ytd];
        break;
      case NavigationType.GraphViews:
        this.navData = [nav];
        break;
      case NavigationType.GraphMenu:
        nav = nav as GraphMenu;
        this.initDropdownData(nav);
        this.hasDropdown = true;
        this.dropdownOption = nav.map(item => item.title);
        break;

      default:
        break;
    }
  }

  private initDropdownData(graph: GraphMenu) {
    const nav = graph[this.currentDropdownIndex].data;
    if (!Array.isArray(nav)) {
      this.createTimeTabs(nav as GraphNavigation);
      this.setDefaultTimeTabIndex();
      this.navData = [nav.month, nav.qtd, nav.ytd];
    } else{
      this.navData = [nav];
    }
  }

  private createTimeTabs(navigation: GraphNavigation): void {
    this.timeTabs = Object.entries(TimePeriods).map(([key, value]) => ({
      key: key,
      name: value,
      disabled: !navigation[key as keyof GraphNavigation],
    }));
  }

  /**
   * This function sets the default index of time tab
   */
  public setDefaultTimeTabIndex(): void {
    if (
      this.timeTabs[this.currentTab] ||
      this.timeTabs[this.currentTab].disabled
    ) {
      const defaultTimeTabIndex = this.timeTabs.findIndex(
        (timeTab) => timeTab && !timeTab.disabled
      );
      if (defaultTimeTabIndex == -1) {
        console.error(`all time tabs are disabled`);
      } else {
        this.currentTab = defaultTimeTabIndex;
      }
    }
  }

  public changeTab(index: number): void {
    if (this.timeTabs[index] && !this.timeTabs[index].disabled && index < this.timeTabs.length) {
      this.currentTab = index;
    } else {
      //TODO: this needs defined behavior, currently will do nothing. this will pose an issue in future graphs where index 0 is disabled
      console.error(`selected tab not allowed`);
    }
  }

  /**
   * This function changes slider config's initial slide index, according to the number of available slides.
   * It will also toggle the view of dots navigation.
   * @param count number of slides
   * @returns slider config
   */
  public sliderCountHandle(count: number): any {
    if (count > 1) {
      this.sliderConfig.dots = true;
    } else {
      this.sliderConfig.dots = false;
    }

    if (this.currentSlide !== undefined) {
      this.sliderConfig.initialSlide = this.currentSlide
    }
    else {
      if (count === 3) {
        this.sliderConfig.initialSlide = this.currentSlide = 1;
      } else {
        this.sliderConfig.initialSlide = this.currentSlide = 0;
      }
    }
    return this.sliderConfig;
  }

  public trackByFunc(index: number, item: any): number {
    return index;
  }

  public toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public changeDataByDropdown(dropdownIndex: number): void {
    this.isDropdownOpen = false;
    this.currentDropdownIndex = dropdownIndex;
    this.initCurrentData();
    this.updateNavState.emit(this.getCurrentNavState());
  }

  public afterChange(obj: any): void {
    this.currentSlide = obj.currentSlide;
  }


  public getBusinessUnits(): NavigationItem<RTSparkChart>[] {
    return this.dataForChart.subMenu ?? [];
 }

 public getSubBusinessUnits() : SubNavigationItem<RTSparkChart> []{
  if(this.dataForChart.subMenu && this.currentBUIndex !== -1){
    return this.dataForChart.subMenu[this.currentBUIndex].data ?? [];
  }
  return [];
 }

 public onChangeUnit(index: number) : void{
  this.currentBUIndex = index;
  this.updateNavState.emit(this.getCurrentNavState());
  this.cdr.detectChanges();
 }

 public onChangeSubUnit(index: number) : void{
  this.currentSBUIndex = index;
  this.updateNavState.emit(this.getCurrentNavState());
  this.cdr.detectChanges();
}

public emitNavState(): void {
   this.updateNavState.emit(this.getCurrentNavState());
   this.cdr.detectChanges();
 }

}
