
<app-vertical-bar-graph
    [selectedColumnIndex]="currentSelectedColumnIndex"
    [graph]="data.graph.data[indexUnit || 0].graph"
    (onBarSelect)="onSelectGarphValue($event)"
    (onUserBarSelect)="onUserBarSelect($event)"
    [type]="getBarType(data)"
    [isLoading]="isLoading"
    [isPercentage]="isPercentage"
    >
</app-vertical-bar-graph>
<app-battery-graph
    [isMainGraph]="!!data.graph.isMainGraph"
    [columnIndex]="currentSelectedColumnIndex"
    [navState]="navState"
    [isLoading]="isLoading"
    [batteries]="data.graph.data[indexUnit || 0].battery[currentSelectedColumnIndex]">
</app-battery-graph>
